import React from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import HoverDropdown from "../Elements/HoverDropdown";

const TeamMember = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 56px;
    align-items: stretch;
    border-bottom: 1px solid ${color("grey")};
    padding-bottom: 56px;

    :first-of-type{
        margin-top: 0;
    }

    :last-of-type{
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }

    @media screen and (min-width: 768px) {
        width: 46%;
        :nth-of-type(2n+1){
            padding-left: 0;
        }
        :nth-of-type(2n){
            padding-right: 0;
        }
        :nth-of-type(2){
            margin-top: 0;
        }
        :nth-last-of-type(2){
            :nth-of-type(2n+1){
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        margin: 0 0 64px;
        padding-bottom: 64px;
    }

    @media screen and (min-width: 1800px) {
        margin: 0 0 72px;
        padding-bottom: 72px;
    }
`;
const MainDetails = styled.div`
    margin-bottom: 40px;

    @media screen and (min-width: 768px) {
        margin-bottom: 32px;
        padding-right: 24px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 0;
        padding-right: 41px;
    }
`;
const Role = styled.p`
    ${typography.label};
    text-transform: uppercase;
    margin-bottom: 16px;
    position: relative;
    color: ${color("dark")};

    @media screen and (min-width: 759px) {
        margin-bottom: 24px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 32px;
    }
`;
const TeamMemberTitle = styled.h3`
    ${typography.h3};
    margin-bottom: 8px;
    font-weight: 600;

    strong{
        font-weight: 600;
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 8px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 16px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 24px;
    }
`;
const TeamMemberDescription = styled.div`
    p{
        ${typography.body1};
    }
`;
const TeamMemberImage = styled.div`
    width: 100%;

    picture, img{
        display: block;
        width: 100%;
        height: auto;
    }
`;
const TeamMemberDetails = styled.div`
    text-decoration: none;
    color: ${color("dark")};
    transition: color 0.2s ease;
    padding: 24px 0 0;
    background-color: ${color("white")};
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: 768px) {
        padding: 16px 0 0;
    }

    @media screen and (min-width: 1200px) {
        padding: 24px 0 0;
        flex-direction: row;
        align-items: flex-start;
    }

    @media screen and (min-width: 1800px) {
        padding: 32px 0 0;
    }
`;

const TeamMemberComponent = ({image, role, name, description, social}) => {
    return (
        <TeamMember>
            <TeamMemberImage>
                <span>
                    {image}
                </span>
            </TeamMemberImage>
            <TeamMemberDetails>
                <MainDetails>
                    <TeamMemberTitle>
                        {name}
                    </TeamMemberTitle>
                    <Role>{role}</Role>
                    <TeamMemberDescription dangerouslySetInnerHTML={{ __html: description }}/>
                </MainDetails>
                <HoverDropdown
                    title="Connect"
                    links={social}
                    reverse={true}
                    hideTitleMobile={true}
                    breakTablet={true}
                    width="100%"
                />
            </TeamMemberDetails>
        </TeamMember>
    );
};

export default TeamMemberComponent;
