import React from "react";
import styled from "@emotion/styled";
import Container from "../Context/Container";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import Section from "../Context/Section";
import FadeUp from "../Effects/FadeUp";

const Title = styled.h1`
    ${typography.h1};
    color: ${color("dark")};
    width: 100%;
    text-align: center;
    display: inline-block;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
        margin-bottom: 20px;
        text-align: left;
        max-width: 882px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 32px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 30px;
    }
`;

const PageIntro = styled.div`
    ${typography.pageIntro};
    ${typography.RTESpacing};
    text-align: center;
    color: ${color("dark")};

    a{
        color: ${color("dark")};
        transition: color 0.2s ease;
        text-decoration: none;

        &:hover{
            color: ${color("dark blue")};
        }
    }

    @media screen and (min-width: 768px) {
        max-width: 100%;
        text-align: left;
    }

    @media screen and (min-width: 1200px) {
        max-width: 660px;
    }

    @media screen and (min-width: 1800px) {
        max-width: 987px;
    }
`;

const GradientWrapper = styled.div`
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`;

const GradientBackground = styled.div`
    position: absolute;
    top: -100%;
    bottom: 0;
    left: -100%;
    right: 0;
    width: 200%;
    height: 200%;
    z-index: 0;
    animation: gradient 9.4s ease 0.6s 1 forwards;

    @keyframes gradient {
        0% {
            transform: translate(0%, 0%);
        }
        100% {
            transform: translate(60%, 10%);
        }
    }

    svg {
        position: absolute;
        bottom: 0%;
        height: 100%;
        width: 100%;
        transform: translateY(50%);
    }
`;

const HeroGradient = ({title, subtitle, theme}) => {
    return (
        <Section paddingS="225px 0 105px" minHeightS="80vh" paddingM="155px 0 72px" minHeightM="80vh" paddingL="171px 0 104px" minHeightL="95vh" paddingXL="171px 0 128px" minHeightXL="85vh" paddingXXL="181px 0 148px" minHeightXXL="75vh" bg="base blue" fg="dark" theme={theme} flexGrow="1">
            <Container type="hero">
                <FadeUp time="2s">
                    <Title>
                        {title}
                    </Title>
                </FadeUp>
                <FadeUp time="2s" delay="0.4s">
                    <PageIntro dangerouslySetInnerHTML={{ __html: subtitle }} />
                </FadeUp>
            </Container>
            <GradientWrapper>
                <GradientBackground>
                    <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <defs>
                        <radialGradient id="myGradient">
                            <stop offset="0%" stop-color={color("base peach")} />
                            <stop offset="100%" stop-color={color("base blue")} />
                        </radialGradient>
                    </defs>
                    <circle cx="5" cy="5" r="5" fill="url('#myGradient')" />
                    </svg>
                </GradientBackground>
            </GradientWrapper>
        </Section>
    );
};

export default HeroGradient;
