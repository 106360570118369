import React from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import Container from "../Context/Container";
import Section from "../Context/Section";
import FadeUp from "../Effects/FadeUp";

const Intro = styled.div`
    margin-bottom: 40px;

    p{
        &:last-of-type{
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 56px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 57px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 67px;
    }
`;
const Row = styled.div`
    margin-bottom: 17px;

    a{
        ${typography.link};
        color: ${color("dark")};
        transition: color 0.2s ease;
        white-space: nowrap;

        &:hover{
            color: ${color("dark blue")};
        }
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 26px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 34px;

        a{
            margin-bottom: 0.6em;
        }
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 28px;
    }
`;
const Title = styled.h2`
    ${typography.h2};
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: ${props => props.marginBottom ? "8px" : "0"};

    @media screen and (min-width: 768px) {
        line-height: 1.1;
        margin-bottom: ${props => props.marginBottom ? "8px" : "0"};
    }

    @media screen and (min-width: 768px) {
        margin-left: 138px;
        margin-right: 138px;
    }

    @media screen and (min-width: 1200px) {
        line-height: 1.05;
        margin-bottom: ${props => props.marginBottom ? "8px" : "0"};
    }

    @media screen and (min-width: 1200px) {
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: ${props => props.marginBottom ? "8px" : "0"};
    }
`;
const Subheading = styled.h3`
    ${typography.label};
    padding-bottom: 8px;
    border-bottom: 1px solid ${color("dark")};
    width: auto;
    display: inline-block;
    margin-bottom: 8px;
    font-weight: 600;

    @media screen and (min-width: 768px) {
        margin-bottom: 16px;
    }

    @media screen and (min-width: 768px) {
        margin-left: 138px;
        margin-right: 138px;
    }

    @media screen and (min-width: 1200px) {
        padding-bottom: 18px;
    }

    @media screen and (min-width: 1200px) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (min-width: 1800px) {
        padding-bottom: 19px;
    }
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (min-width: 1200px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

const Col = styled.div`
    margin-bottom: 48px;

    &:last-of-type{
        margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 40px;

        &:last-of-type{
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 50px;
        padding: 0 30px;
        width: 33.333333%;

        &:first-of-type{
            padding-left: 0;
        }

        &:last-of-type{
            padding-right: 0;
        }

        &:nth-of-type(3n){
            padding-right: 0;
        }

        &:nth-of-type(3n + 1){
            padding-left: 0;
        }

        &:nth-last-of-type(2), &:nth-last-of-type(3){
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 1800px) {
        padding: 0 33px;

        &:first-of-type{
            padding-left: 0;
        }

        &:last-of-type{
            padding-right: 0;
        }
    }
`;

const Small = styled.div`
    ${typography.small};
    ${typography.RTESpacing};

    p, ul, h1, h2, h3, h4, h5, h6{
        @media screen and (min-width: 768px) {
            margin-left: 138px;
            margin-right: 138px;
        }

        @media screen and (min-width: 1200px) {
            margin-left: 0;
            margin-right: 0;
        }
    }
`;

const Standard = styled.div`
    ${typography.standard};
    p{

        @media screen and (min-width: 768px) {
            margin-left: 138px;
            margin-right: 138px;
        }

        @media screen and (min-width: 1200px) {
            margin-left: 0;
            margin-right: 0;
            max-width: 55%;
        }
    }
`;

const Philosophy = ({title, intro, content, theme}) => {

    const contentComponents = content.map(piece => {
        return (
            <Col key={piece.id}>
                <Subheading>
                    {piece.subheading}
                </Subheading>
                <Small dangerouslySetInnerHTML={{ __html: piece.description }}/>
            </Col>
        );
    })

    return (
        <Section bg="background blue" fg="dark" theme={theme}>
            <Container>
                <Intro>
                    <Row>
                        <FadeUp>
                            <Title marginBottom={intro}>
                                {title}
                            </Title>
                        </FadeUp>
                        {intro && <Standard dangerouslySetInnerHTML={{ __html: intro }} />}
                    </Row>
                </Intro>
                <Text>
                    {contentComponents}
                </Text>
            </Container>
        </Section>
    );
};

export default Philosophy;
