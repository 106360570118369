import React from "react";

import GatsbyImage from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import HeroGradient from "../components/General/HeroGradient";
// import Intro from "../components/General/Intro";
import CTA from "../components/General/CTA";
import Footer from "../components/Context/Footer";
import Team from "../components/About/Team";
import Philosophy from "../components/General/Philosophy";
import Approach from "../components/About/Approach";
import ImageSpan from "../components/About/ImageSpan";

import { graphql } from "gatsby";
import usePreviewData from "../util/usePreviewData";

const About = ({ data: staticData }) => {
    const data = usePreviewData(staticData);
    const {
        name,
        tagline,
        heading_intro,
        intro,
        heading_how,
        subheading_how_1,
        description_how_1,
        subheading_how_2,
        description_how_2,
        subheading_how_3,
        description_how_3,
        image_how,
        heading_our,
        description_our,
        subheading_our_1,
        description_our_1,
        subheading_our_2,
        description_our_2,
        subheading_our_3,
        description_our_3,
        heading_team,
        people,
        heading_promo,
        teaser,
        button_copy,
        link_promo,
        meta_description,
        share_image
    } = data.prismicAbout.data;

    const teamMembers = people.map(person => {
        const socialLinks = [];

        if(person.email) socialLinks.push({
            name: "Email",
            url: person.email,
            key: `email_${person.name_people.text}_${person.role}`
        });

        if(person.twitter && person.twitter.url) socialLinks.push({
            name: "Twitter",
            url: person.twitter.url,
            key: `twitter_${person.name_people.text}_${person.role}`
        });

        if(person.linkedin && person.linkedin.url) socialLinks.push({
            name: "LinkedIn",
            url: person.linkedin.url,
            key: `linkedin_${person.name_people.text}_${person.role}`
        });

        return (
            {
                description: person.description.html,
                role: person.role,
                name: person.name_people.text,
                image: person.portrait.fluid ? <GatsbyImage fluid={person.portrait.fluid} alt={person.portrait.alt} /> : null,
                social: socialLinks
            }
        );
    });

    const philosophyContent = [{
        id: 1,
        subheading: subheading_how_1.text,
        description: description_how_1.html
    },
    {
        id: 2,
        subheading: subheading_how_2.text,
        description: description_how_2.html
    },
    {
        id: 3,
        subheading: subheading_how_3.text,
        description: description_how_3.html
    }];

    const theme = "light";
    return (
        <Layout theme={theme}>
            <SEO title={name.text} description={meta_description} image={share_image.url} />
            <Header theme={theme} />
            <HeroGradient
                title={name.text}
                subtitle={tagline.html}
                theme={theme}
            />
            <Approach
                theme={theme}
                title={heading_intro.text}
                content={intro.html}
            />
            <Philosophy
                theme={theme}
                title={heading_how.text}
                content={philosophyContent}
            />
            {image_how.fluid && (
                <ImageSpan
                    theme={theme}
                    image={<GatsbyImage fluid={image_how.fluid} alt={image_how.alt} />}
                />
            )}
            <Approach
                theme={theme}
                title={heading_our.text}
                content={description_our.html}
                subheading1={subheading_our_1.text}
                content1={description_our_1.html}
                subheading2={subheading_our_2.text}
                content2={description_our_2.html}
                subheading3={subheading_our_3.text}
                content3={description_our_3.html}
                border={true}
            />
            <Team
                theme={theme}
                title={heading_team.text}
                teamMembers={teamMembers}
            />
            <CTA
                theme={theme}
                title={heading_promo.text}
                content={teaser.html}
                buttonText={button_copy}
                buttonUrl={link_promo.url || "/"}
                buttonInternal={true}
            />
            <Footer theme={theme} />
        </Layout>
    );
};

export const pageQuery = graphql`
    {
        prismicAbout {
            data {
                button_copy
                description_how_1 {
                    html
                }
                description_how_2 {
                    html
                }
                description_how_3 {
                    html
                }
                description_our {
                    html
                }
                description_our_1 {
                    html
                }
                description_our_2 {
                    html
                }
                description_our_3 {
                    html
                }
                image_how {
                    alt
                    fluid(maxWidth: 1400) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                heading_intro {
                    text
                }
                heading_how {
                    text
                }
                heading_our {
                    text
                }
                heading_promo {
                    text
                }
                heading_team {
                    text
                }
                intro {
                    html
                }
                link_promo {
                    url
                    target
                    link_type
                }
                name {
                    text
                }
                meta_description
                people {
                    description {
                        html
                    }
                    email
                    linkedin {
                        url
                        target
                        link_type
                    }
                    name_people {
                        text
                    }
                    portrait {
                        alt
                        fluid(maxWidth: 640) {
                            ...GatsbyPrismicImageFluid
                        }
                    }
                    role
                    twitter {
                        target
                        url
                        link_type
                    }
                }
                share_image {
                    url
                }
                subheading_how_1 {
                    text
                }
                subheading_how_2 {
                    text
                }
                subheading_how_3 {
                    text
                }
                subheading_our_1 {
                    text
                }
                subheading_our_2 {
                    text
                }
                subheading_our_3 {
                    text
                }
                tagline {
                    html
                }
                teaser {
                    html
                }
            }
        }
    }
`;

export default About;
