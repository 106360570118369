import React from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import Container from "../Context/Container";
import Section from "../Context/Section";

const WhiteBackgroundSection = styled.div`
    background: ${color("white")};
    position: absolute;
    height: 43%;
    width: 100%;
    bottom: 0;
    left: 0;

    @media screen and (min-width: 1200px) {
        height: 50%;
    }

    img{
        width: 100%;
    }
`;

const ImageSpan = ({image, theme}) => {
    return (
        <Section paddingS="0" paddingM="0" paddingL="0" paddingXL="0" paddingXXL="0" bg="blue" fg="white" theme={theme}>
            <WhiteBackgroundSection />
            <Container>
                {image}
            </Container>
        </Section>
    );
};

export default ImageSpan;
