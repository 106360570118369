import React from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import TeamMember from "./TeamMember";
import Container from "../Context/Container";
import Section from "../Context/Section";

const Intro = styled.div`
    margin-bottom: 40px;

    p{
        &:last-of-type{
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 56px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 57px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 49px;
    }
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;

    a{
        ${typography.link};
        color: ${color("dark")};
        display: flex;
        flex-direction: row;
        transition: color 0.2s ease;
        white-space: nowrap;

        &:hover{
            color: ${color("dark blue")};
        }
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 26px;
    }

    @media screen and (min-width: 1200px) {
        align-items: flex-end;
        margin-bottom: 34px;

        a{
            margin-bottom: 0.6em;
        }
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 28px;
    }
`;
const Title = styled.h2`
    ${typography.h2};
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
        line-height: 1.1;
        margin-bottom: 0;
    }

    @media screen and (min-width: 1200px) {
        line-height: 1.05;
        margin-bottom: 0;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 0;
    }
`;

const TeamWrapper = styled.div`
    width: 100%;

    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
    }
`;

const Team = ({title, teamMembers, theme}) => {

    const TeamMemberList = teamMembers.map(teamMember => {
        return (
            <TeamMember
                name={teamMember.name}
                image={teamMember.image}
                description={teamMember.description}
                role={teamMember.role}
                social={teamMember.social}
                key={`${teamMember.name}_${teamMember.role}`}
            />
        )
    });

    return (
        <Section bg="white" fg="dark" hr="grey" theme={theme}>
            <Container>
                <Intro>
                    <Row>
                        <Title>
                            {title}
                        </Title>
                    </Row>
                </Intro>
                <TeamWrapper>
                    {TeamMemberList}
                </TeamWrapper>
            </Container>
        </Section>
    );
};

export default Team;
