import React from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import Container from "../Context/Container";
import Section from "../Context/Section";
import FadeUp from "../Effects/FadeUp";

const Intro = styled.div`
    margin-bottom: ${props => props.hasBottomMargin ? "40px" : "0"};

    p{
        &:last-of-type{
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 768px) {
        margin-bottom: ${props => props.hasBottomMargin ? "56px" : "0"};
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: ${props => props.hasBottomMargin ? "57px" : "0"};
        display: flex;
        flex-direction: row;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: ${props => props.hasBottomMargin ? "49px" : "0"};
    }
`;
const Title = styled.h2`
    ${typography.h2};
    color: ${color("dark")};
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
        line-height: 1.1;
        margin-bottom: 24px;
    }

    @media screen and (min-width: 1200px) {
        line-height: 1.05;
        margin-bottom: 0;
        padding-right: 50px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 0;
    }
`;
const Subheading = styled.h3`
    ${typography.h3};
    padding-bottom: 8px;
    color: ${color("dark blue")};
    border-bottom: 1px solid ${color("grey")};
    width: auto;
    display: inline-block;
    margin-bottom: 8px;
    font-weight: 600;

    @media screen and (min-width: 768px) {
        padding-bottom: 16px;
        margin-bottom: 16px;
    }
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    @media screen and (min-width: 1200px) {
        flex-wrap: nowrap;
    }
`;

const Col = styled.div`
    margin-bottom: 48px;

    &:last-of-type{
        margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
        padding: 0 28px;
        margin-bottom: 40px;
        width: 50%;

        &:nth-of-type(2n+1)&:not(:last-of-type){
            padding-left: 0;
        }

        &:nth-of-type(2n){
            padding-right: 0;
        }

        &:last-of-type{
            margin-bottom: 0;
            padding-right: 0;
        }
    }

    @media screen and (min-width: 1200px) {
        padding: 0 30px;
        margin-bottom: 0;

        &:first-of-type{
            padding-left: 0;
        }

        &:last-of-type{
            padding-right: 0;
        }
    }

    @media screen and (min-width: 1800px) {
        padding: 0 33px;

        &:first-of-type{
            padding-left: 0;
        }

        &:last-of-type{
            padding-right: 0;
        }
    }
`;

const Standard = styled.div`
    ${typography.standard};
    ${typography.RTESpacing};
    color: ${color("dark")};
    max-width: 504px;

    @media screen and (min-width: 1200px) {
        max-width: none;
        width: 62.5%;
    }

    @media screen and (min-width: 1800px) {
        width: 50%;
    }
`;

const Small = styled.div`
    ${typography.small};
    ${typography.RTESpacing};
    color: ${color("dark")};
`;

const TitleWrapper = styled(FadeUp)`
    flex-grow: 1;
    flex-basis: 0;
`;

const Approach = ({title, content, subheading1, content1, subheading2, content2, subheading3, content3, border, theme}) => {
    const hasTextContent = subheading1 && subheading2 && subheading3;

    return (
        <Section bg="white" fg="dark" hr={border ? "grey" : null} theme={theme}>
            <Container>
                <Intro hasBottomMargin={hasTextContent}>
                    <TitleWrapper>
                        <Title>
                            {title}
                        </Title>
                    </TitleWrapper>
                    <Standard dangerouslySetInnerHTML={{ __html: content }}/>
                </Intro>
                {hasTextContent &&
                    <Text>
                        <Col>
                            <Subheading>
                                {subheading1}
                            </Subheading>
                            <Small dangerouslySetInnerHTML={{ __html: content1 }}/>
                        </Col>
                        <Col>
                            <Subheading>
                                {subheading2}
                            </Subheading>
                            <Small dangerouslySetInnerHTML={{ __html: content2 }}/>
                        </Col>
                        <Col>
                            <Subheading>
                                {subheading3}
                            </Subheading>
                            <Small dangerouslySetInnerHTML={{ __html: content3 }}/>
                        </Col>
                    </Text>
                }
            </Container>
        </Section>
    );
};

export default Approach;
